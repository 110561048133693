import { SITE_CONFIG } from '@config/siteConfig';
import { LatestArticlesSliderTileSkeleton } from '@domains/cityLanding/components/LatestArticlesSection/components/LatestArticlesSliderTile/LatestArticlesSliderTile.skeleton';
import { NexusSectionWrapper } from '@domains/home/components/NexusSectionWrapper/NexusSectionWrapper';
import { ARTICLES_QUERY } from '@domains/home/graphql/queries/Articles';
import { LocalLinkContainer } from '@domains/home/HomePage.theme';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { Slider } from '@domains/shared/components/Slider/Slider';
import { SEO_QUERY_CONTEXT } from '@domains/shared/consts/seoQueryContext';
import { repeatItem } from '@domains/shared/helpers/repeatItem';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC, JSX } from 'react';
import { useQuery } from 'urql';

import { NexusArticle } from './components/NexusArticle/NexusArticle';
import { LinkLabel, SliderWrapper } from './NexusArticlesSection.theme';

const { moreArticles: MORE_ARTICLES_URL } = SITE_CONFIG.atlasRedirects;

const EXPECTED_ARTICLES_TYPENAMES = ['ArticlesPages'] as const;

export const NexusArticlesSection: FC = () => {
    const { trackEvent } = useTracking();
    const { lang } = useSiteSettings();
    const [t] = useTranslations();

    const handleOnMoreArticlesClick = (): void => {
        trackEvent('see_more_articles_click', {
            touch_point_button: 'articles',
        });
    };

    const [{ data, fetching, error, operation }] = useQuery({
        query: ARTICLES_QUERY,
        variables: {
            input: {
                lang,
                last: 8,
            },
        },
        context: SEO_QUERY_CONTEXT,
    });

    const articles = useAssertGraphqlResponse({
        fetching,
        data: data?.articles,
        expectedTypenames: EXPECTED_ARTICLES_TYPENAMES,
        logErrorPrefix: '[ArticlesSection]',
        graphqlError: error,
        operation,
    });

    if (fetching) {
        return (
            <NexusSectionWrapper headline={t('frontend.homepage.articles-section.header')}>
                <div data-testid="articles-wrapper">
                    <Slider
                        items={repeatItem(4, (id) => ({ id: id.toString() }))}
                        renderItem={(): JSX.Element => <LatestArticlesSliderTileSkeleton />}
                        trackingTouchPointButton="articles"
                    />
                </div>
            </NexusSectionWrapper>
        );
    }

    if (!articles || articles.items.length === 0) {
        return null;
    }

    return (
        <NexusSectionWrapper
            headline={t('frontend.homepage.articles-section.header')}
            link={{
                label: t('frontend.homepage.nexus-articles-section.more-articles-link'),
                onClick: handleOnMoreArticlesClick,
                href: MORE_ARTICLES_URL,
            }}
            isScrollableHorizontally
        >
            <SliderWrapper data-testid="articles-wrapper">
                <Slider
                    items={articles.items}
                    renderItem={(article): JSX.Element => (
                        <NexusArticle key={article.id} article={article} lang={lang} />
                    )}
                    buttonTopPosition={-72}
                    shouldUseEdgePaddings
                />
                <LocalLinkContainer>
                    <LocalLink href={MORE_ARTICLES_URL} passHref>
                        <a onClick={handleOnMoreArticlesClick} data-cy="redirect-to-blog">
                            <LinkLabel variant="link">
                                {t('frontend.homepage.nexus-articles-section.more-articles-link')}
                            </LinkLabel>
                        </a>
                    </LocalLink>
                </LocalLinkContainer>
            </SliderWrapper>
        </NexusSectionWrapper>
    );
};

export default NexusArticlesSection;
