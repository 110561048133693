import type { Locale } from '@lib/i18n/types/locale';

import { getPublicEnvConfig } from './getEnvConfig';

const { tz } = getPublicEnvConfig();

/**
 * Used to return a formatted date string in given locale.
 *
 * Note: This function can cause react warnings when timeZone is not provided.
 * - [#418 warning](https://react.dev/errors/418?invariant=418)
 * - [#425 warning](https://react.dev/errors/425?invariant=425)
 * - [#423 warning](https://react.dev/errors/423?invariant=423)
 *
 * even if not relying on `Date.now()`.
 *
 * Double check it's usage on preview environment. Warning is not visible locally for both dev and prod build.
 *
 * Solutions:
 * - make sure you are using correct `timeZone` property
 * - move your method to be used on client side only
 * - use `suppressHydrationWarning` prop on parent HTML tag
 *
 * Docs:
 * - https://react.dev/reference/react-dom/client/hydrateRoot#suppressing-unavoidable-hydration-mismatch-errors
 * - https://nextjs.org/docs/messages/react-hydration-error
 * - https://react.dev/reference/react-dom/components/common#common-props
 *
 * @param lang Locale type string
 * @param date string or Date object
 * @returns formatted date string in given locale. Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 */
export const formatDate = (lang: Locale, date: string | Date): string =>
    (date instanceof Date ? date : new Date(date)).toLocaleDateString(lang, {
        timeZone: tz,
    });
