import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import Calendar from '@nexus/lib-react/dist/core/Icon/icons/default/Calendar';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const TILE_WIDTH = 296;

export const Tile = styled.a`
    display: block;
    width: ${TILE_WIDTH}px;
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
    text-decoration: none;
`;

export const BottomContainer = styled.span`
    display: block;
    padding: ${theme.space['16']};
`;

export const Image = styled.img`
    display: block;
    object-fit: cover;
`;

export const LabelWrapper = styled.span`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.space['18']};
`;

export const CalendarIcon = styled(Calendar)`
    margin-right: ${theme.space['8']};
`;

export const Title = styled(P3)`
    display: -webkit-box;
    height: 54px;
    margin-bottom: ${theme.space['4']};
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
