import { SITE_CONFIG } from '@config/siteConfig';
import type { Article as ArticleType } from '@domains/home/types/article';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { formatDate } from '@domains/shared/helpers/formatDate';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { Locale } from '@lib/i18n/types/locale';
import { useTracking } from '@lib/tracking/useTracking';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { BottomContainer, CalendarIcon, Image, LabelWrapper, Tile, TILE_WIDTH, Title } from './NexusArticle.theme';

interface Props {
    article: ArticleType;
    lang: Locale;
}

export const NexusArticle = ({ article, lang }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const displayDate = (date: string): string => formatDate(lang, date);
    const articlesRef = useRef<HTMLAnchorElement>(null);
    const { moreArticles: moreArticlesUrl } = SITE_CONFIG.atlasRedirects;
    const articlesUrl = `${moreArticlesUrl}${article.url}`;

    const articleTrackingData = useMemo(
        () => ({
            article_id: article.id,
        }),
        [article.id],
    );

    const handleOnClick = (): void => {
        trackEvent('articles_click', articleTrackingData);
    };

    const handleTrackImpression = useCallback(
        (_: IntersectionObserverEntry | undefined, observer: IntersectionObserver | undefined): void => {
            const { current } = articlesRef;

            trackEvent('articles_impression', articleTrackingData);

            if (current && observer) {
                observer.unobserve(current);
                observer.disconnect();
            }
        },
        [trackEvent, articleTrackingData],
    );

    useIntersection(articlesRef, handleTrackImpression);

    // OneTrust recognizes images as tracking ones, adding data-ot-ignore="" attr will tell OT to ignore them so, it won't replace src by data-src attr.
    return (
        <LocalLink href={articlesUrl} passHref>
            <Tile onClick={handleOnClick} ref={articlesRef} data-testid="article-card">
                <Image
                    src={article.image}
                    alt={article.title}
                    width={TILE_WIDTH}
                    height="166"
                    loading="lazy"
                    decoding="async"
                    data-ot-ignore=""
                />
                <BottomContainer>
                    <LabelWrapper>
                        <CalendarIcon />
                        <P3 role="timer">{displayDate(article.date)}</P3>
                    </LabelWrapper>
                    <Title role="heading">{article.title}</Title>
                </BottomContainer>
            </Tile>
        </LocalLink>
    );
};
